// Function of checking Biometric is supported by the device or Not 
export const IsBiomentricSupported = (): boolean => {
  try {
    // Check if Web Authentication API is supported
    if (!window.PublicKeyCredential) {
      return false;
    }

    // Check if the necessary properties and methods are available
    if (
      typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== 'function' ||
      typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable === 'undefined'
    ) {
      return false;
    }

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error checking biometric support:', error);
    return false;
  }
};
