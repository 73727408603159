import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button } from '@storybook/button';
import { activeScreenState } from 'states';

import { STEP_VERIFICATION_MESSAGE, livenessStatus } from 'constant';
import './liveness-information.scss';
import React, { useMemo } from 'react';
import { Loader } from '@storybook';
import { livenessGestureInfoState } from 'states/gesture-state';
import { Image } from '@storybook/image';
import { useGestures } from 'views/liveness/hooks';
interface LivenessInformationProps {
	isLoader: boolean;
}
export const LivenessInformation: React.FC<LivenessInformationProps> = ({
	isLoader,
}) => {
	// globle states
	const setActiveScreen = useSetRecoilState(activeScreenState);
	const livenessGestureInfo = useRecoilValue(livenessGestureInfoState);

	// hook
	const { customerPatchStatus } = useGestures();

	// Function to navigate
	const handleOnClick = () => {
		if (livenessGestureInfo.customerStatus === livenessStatus.PENDING) {
			customerPatchStatus(
				livenessStatus.STARTED,
				livenessGestureInfo?.customerId
			);
		}
		setActiveScreen('liveness-verification');
	};

	// Function for btn disble state
	const manageBtnState = useMemo(() => {
		return !livenessGestureInfo.gesture.length;
	}, [livenessGestureInfo]);
	const labels = useMemo(() => {
		if (isLoader) {
			return <Loader type="loader" dimension={20} />;
		}
		return 'Begin Liveness Verification';
	}, [isLoader]);
	//Rahul Singh: screen for liveness information.
	return (
		<>
			{isLoader ? (
				<div className='LivenessInformation--container__loader-wrapper'>
					<div className='LivenessInformation--container__loader-wrapper-box'>
					<Loader />
					</div>
				</div>
			) : (
				<div className="LivenessInformation--container">
					<div className="LivenessInformation--container__content">
						<h2 className="LivenessInformation--container__content__heading">
							{STEP_VERIFICATION_MESSAGE.TITLE}
						</h2>
						<p className="LivenessInformation--container__content__subheading">
							{STEP_VERIFICATION_MESSAGE.HEADING}
						</p>
						<ul className="LivenessInformation--container__content__list">
							<li className="LivenessInformation--container__content__list__list-item">
								{STEP_VERIFICATION_MESSAGE.STEPONE}
							</li>
							<li className="LivenessInformation--container__content__list__list-item">
								{STEP_VERIFICATION_MESSAGE.STEPTWO}
							</li>
							<li className="LivenessInformation--container__content__list__list-item">
								{STEP_VERIFICATION_MESSAGE.STEPTHREE}
							</li>
						</ul>
					</div>
					<Image
						fileName="liveness-info-image.svg"
						className="LivenessInformation--container__imageinfo"
					/>
					<div className="LivenessInformation__button--info btn-begin-liveness">
						<Button
							label={labels}
							type="button__filled--primary"
							handleClick={handleOnClick}
							disabled={manageBtnState}
						/>
					</div>
				</div>
			)}
		</>
	);
};
