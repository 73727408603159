import { useCallback, useEffect, useMemo, useState } from 'react';
import { Loader } from '@storybook';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ISelectTermCondtionState, activeScreenState } from 'states';
import {
	BIOMETRIC_POLICY_ONETRUST_TOKEN,
	PRIVACY_POLICY_LINK,
	TERMS_CONDTION_ONETRUST_TOKEN,
} from './constants';

import './term-and-condition.scss';

export const TermAndCondtion = () => {
	const selectTermCondtion = useRecoilValue(ISelectTermCondtionState);
	const setActiveScreen = useSetRecoilState(activeScreenState);

	const handlebackButton = useCallback(() => {
		setActiveScreen('payment-approval');
	}, []);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (selectTermCondtion === 'term-and-condition') {
			const OneTrust = (window as any).OneTrust;
			if (OneTrust?.NoticeApi?.Initialized) {
				OneTrust.NoticeApi.Initialized.then(function () {
					OneTrust.NoticeApi.LoadNotices([TERMS_CONDTION_ONETRUST_TOKEN.url]);
				});
			}
		}
		const timeoutId = setTimeout(() => setIsLoading(false), 500);

		// Clean up function to clear the timeout if component unmounts or dependencies change
		return () => clearTimeout(timeoutId);
	}, [setIsLoading]);

	useEffect(() => {
		if (selectTermCondtion === 'biometric-policy') {
			const OneTrust = (window as any).OneTrust;
			OneTrust.NoticeApi.Initialized.then(function () {
				OneTrust.NoticeApi.Initialized.then(function () {
					OneTrust.NoticeApi.LoadNotices([BIOMETRIC_POLICY_ONETRUST_TOKEN.url]);
				});
			});
		}
		setTimeout(() => setIsLoading(false), 500);
	}, [isLoading]);

	const renderPolicySection = useMemo(() => {
		switch (selectTermCondtion) {
			case 'term-and-condition':
				return (
					<div
						style={{ display: isLoading ? 'none' : 'block' }}
						id={TERMS_CONDTION_ONETRUST_TOKEN.tokenId}
						className="otnotice"
					></div>
				);
			case 'privacy-policy':
				return (
					<div className="privacy-container">
						<iframe
							title="Privacy Policy"
							src={PRIVACY_POLICY_LINK}
							className="privacy-frame"
						/>
					</div>
				);
			case 'biometric-policy':
				return (
					<div
						style={{ display: isLoading ? 'none' : 'block' }}
						id={BIOMETRIC_POLICY_ONETRUST_TOKEN.tokenId}
						className="otnotice"
					></div>
				);
		}
	}, [selectTermCondtion, isLoading]);

	return (
		<div className="terms">
			<div onClick={handlebackButton} className="terms__sticky-term-policy">
				<i className="ri-close-circle-fill"></i>
			</div>
			{isLoading && (
				<div className="loader-wrapper">
					<Loader className="loader-blue" dimension={40} />
				</div>
			)}
			<div className="privacy-container">{renderPolicySection}</div>
		</div>
	);
};
