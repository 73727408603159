import { FC } from 'react';


import './loader.scss';
import {IMAGE_GCP_URL}  from '@storybook/constant/comman';


interface ILoaderDimension {
	dimension?: number;
	className?: 'loader-blue' | 'loader-white' | 'loader-pink';
	type?: 'loader' | 'gif';
}

export const Loader: FC<ILoaderDimension> = ({
	dimension,
	className = 'loader-blue',
	type = 'gif',
}) => {
	let _dimension = dimension;
	if (!dimension) {
		_dimension = 70;
	}
	return (
		<div className="circle-loader">
			{type === 'gif' ? (
				<img
					style={{ width: _dimension, height: _dimension }}
					src={`${IMAGE_GCP_URL}/simpliciGif.gif`}
					alt=""
				/>
			) : (
				<div
					style={{ width: _dimension, height: _dimension }}
					className={`loader ${className}`}
				/>
			)}
		</div>
	);
};
