import React, { FC } from 'react';
import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Main } from 'main';
import './index.scss';

// Cosmatics variables
import './variables/_colors.scss';
import './variables/_font-size.scss';
import './variables/_line-heights.scss';
import './variables/_margin.scss';
import './variables/_padding.scss';
import './variables/_border-radius.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'remixicon/fonts/remixicon.css';
import { Slide, ToastContainer } from 'react-toastify';

export interface IWebComponentProps {
	verfication_id?: string;
}

const Root: FC<IWebComponentProps> = (props) => {
	return (
		<React.StrictMode>
			<RecoilRoot>
				<ToastContainer
					position="top-center"
					autoClose={2000}
					hideProgressBar
					closeOnClick
					pauseOnHover
					transition={Slide}
					limit={1}
				/>
					<Main {...props} />
			</RecoilRoot>
		</React.StrictMode>
	)
}

class Verfication extends HTMLElement {
	connectedCallback() {
	const props: Record<string, any> = {}
	const attributes = this.getAttributeNames();
	
	attributes.forEach(attr => {
		const propData = this.getAttribute(attr);
		props[attr] = propData;
	})
		// eslint-disable-next-line react/no-deprecated
		ReactDOM.render(<Root {...props} />, this);
	}
  
	disconnectedCallback() {
		// eslint-disable-next-line react/no-deprecated
		ReactDOM.unmountComponentAtNode(this);
	}
  
	static get observedAttributes() {
		return ['verfication_id'];
	}
  
	attributeChangedCallback(_: string, oldValue: any, newValue: any) {
		if (newValue !== oldValue) {
			this.connectedCallback();
		}
	}
  }
  
customElements.define('simplici-verfication', Verfication);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
