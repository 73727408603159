import { atom } from 'recoil';
export const IframePropsState = atom<any>({
	key: 'iframe-props-state',
	default: {
		background: '',
		theadColor: '',
		isSearch: false,
		clientId: '',
		clientSecret: '',
		btnPrimary: '',
		btnSecondary: '',
		notification: false,
		webAdminType: '',
		sidebar: false,
	},
});