/* eslint-disable no-console */
import { envHost } from 'helpers/env-url';
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	IsLiveNessDone,
	IsWebRtcConnectedState,
	activeScreenState,
} from 'states';
import { userInformationState } from 'states/gesture-state';
import { useFace } from './get-user-face';
type SocketState = WebSocket | null;

// TODO: @anuj 
// TEMP var as useRef have current key
const socketStateRef: {current: SocketState} = {
	current: null,
}

export const useWebSocketConnection = () => {
	const userInformations = useRecoilValue(userInformationState);
	const setWebRtcConnected = useSetRecoilState(IsWebRtcConnectedState);
	const setActiveScreen = useSetRecoilState(activeScreenState);
	const isLiveNess = useRecoilValue(IsLiveNessDone);
	const { stopCamera } = useFace();

	const { user, customer } = userInformations ?? {};

	const sendFrameToserver = useCallback(() => {
		const video = document.getElementById('webcam') as HTMLVideoElement;
		const canvasElement = document.createElement('canvas');
		const ctx = canvasElement.getContext('2d');
		const sendFrame = () => {
			if (
				socketStateRef.current &&
				socketStateRef.current.readyState === WebSocket.OPEN
			) {
				if (ctx && video && video?.videoHeight && video?.videoWidth) {
					// Ensure canvas size matches video size
					canvasElement.width = video.videoWidth;
					canvasElement.height = video.videoHeight;

					// Draw the video frame on the canvas
					ctx.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);

					// Convert the canvas content to a Blob (JPEG format in this case)
					canvasElement.toBlob((blob) => {
						if (socketStateRef.current) {
							// Send the Blob data over the WebSocket
							socketStateRef.current.send(blob ?? '');
						}
					}, 'image/jpeg');
				}
			}

			// Use requestAnimationFrame to call the function again on the next frame
			requestAnimationFrame(sendFrame);
		};
		sendFrame();
	}, [socketStateRef]);

	const sendCustomerDataToServer = useCallback(() => {
		console.log('socket state:- ', socketStateRef.current);
		if (socketStateRef.current) {
			socketStateRef.current.send(
				JSON.stringify({
					env: envHost,
					email: user?.email_id ?? '',
					phone: user?.phone_no ?? '',
					customerId: customer?.id ?? '',
					face_match: customer?.face_match ?? false,
				})
			);
		}
		sendFrameToserver();
	}, [socketStateRef, user, customer]);
	// function for stop the camera
	const handleStopCamera = useCallback(() => {
		const video = document.getElementById('webcam') as HTMLVideoElement;
		stopCamera(video);
	}, []);

	const closeRequestToServer = useCallback(() => {
		console.log('soket close in state', socketStateRef.current);
		console.log('connect close request sent');
		if (socketStateRef.current) {
			socketStateRef.current.close();
		}
	}, [socketStateRef]);

	const createWebsocketConnection = useCallback(() => {

		// TODO: @anuj
		// Temp fix to fix websocket connection issue, uncomment when we start matching the face using websocket
		setWebRtcConnected(true);

		console.log('createWebsocketConnection---called');
		// Establish a WebSocket connection
		const socket = new WebSocket(
			`wss://faceid-biometric.satschel.com/rocket/ws/${user?.phone_no}`
		); // Replace with your server URL
		socketStateRef.current = socket;
		console.log('socket-', socketStateRef.current.readyState);
		// setSocketState(socket);

		// Handle connection open
		socket.addEventListener('open', (event) => {
			sendCustomerDataToServer();
			console.log('WebSocket connection opened:', event);
		});
		// Handle incoming messages
		socket.addEventListener('message', (event) => {
			if (JSON.parse(event.data).acknowledge) {
				setWebRtcConnected(true);
			}
			console.log('WebSocket message received:', JSON.parse(event.data));
		});

		// Handle connection close
		socket.addEventListener('close', (event) => {
			if (socket.readyState === 0) {
				handleStopCamera();
				setActiveScreen('connection-not-created');
			}
			console.log('WebSocket connection closed :', event);
		});
		
	}, [user, isLiveNess]); // Only run the effect once when the component mounts

	return { createWebsocketConnection, closeRequestToServer };
};
