import { useEffect, useState } from 'react';

export const useCheckOffline = () => {
	// local state
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		// function to handle the online event
		function onlineHandler() {
			setIsOnline(true);
		}
		// function to handle the offline event
		function offlineHandler() {
			setIsOnline(false);
		}
		//Add event listeners for online and offline events
		window.addEventListener('online', onlineHandler);
		window.addEventListener('offline', offlineHandler);

		// Cleanup: Remove event listeners when the component unmounts
		return () => {
			window.removeEventListener('online', onlineHandler);
			window.removeEventListener('offline', offlineHandler);
		};
	}, []);

	return { isOnline, setIsOnline };
};
