import { Image } from '@storybook';
import { TransactionDecline } from 'views/payment-acceptance/constant';

export const TransctionRejectedScreen = () => {
	return (
		<div className='PaymentAcceptance--container'>
			<div className="PaymentAcceptance--container__Decline">
				<Image fileName="paymentDecline.svg" />
				<div className="PaymentAcceptance--container__Decline-header">
					{TransactionDecline.Header}
				</div>
				<div className="PaymentAcceptance--container__Decline-subHeader">
					{TransactionDecline.SubHeader}
				</div>
			</div>
		</div>
	);
};
