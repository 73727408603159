import { Image } from '@storybook';

import './liveness-restriction.scss';

export const LivenessRestriction = () => {
	return (
		<div className="restriction">
			<Image fileName="verification-order.svg" />
			<h2 className='restriction__heading'> Verification order in progress</h2>
			<div className='restriction__sub-heading'>
				A verification order is currently in progress to complete this
				transaction. Please wait until the designated user finish their
				respective verification processes before accessing this page
			</div>
		</div>
	);
};
