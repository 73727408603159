import { useCallback, useMemo, useState } from 'react';

import { REACT_APP_API_HOST as API_HOST } from 'envs';
import { getAccessToken } from 'utils';

interface IConfig {
	headers?: {
		Authorization?: string;
		'Content-Type'?: string;
	};
}

export const useNetwork = () => {
	// local states
	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [status, setStatus] = useState<any>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const config: IConfig = useMemo(() => ({}), []);
	const postConfig: IConfig = useMemo(() => ({}), []);

	const accessToken = getAccessToken();
	if (accessToken) {
		config.headers = {
			Authorization: `Bearer ${accessToken}`,
		};
	}

	postConfig.headers = {
		'Content-Type': 'application/json',
		...(config.headers ?? {}),
	};

	const get = useCallback(
		async (url: string, customHost?: string): Promise<any> => {
			setLoading(true);

			try {
				const response = await fetch(
					customHost ? customHost + url : API_HOST + url,
					config
				);
				const apiPayload = await response.json();
				setStatus(response?.ok);
				setIsLoaded(true);
				const payload = {
					statusCode: response.status,
					...apiPayload,
				};
				setData(payload);
				return payload;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[config]
	);

	const post = useCallback(
		async (
			url: string,
			requestJSON: any,
			customHost?: string,
			customConfig?: any
		) => {
			setLoading(true);
			if (customConfig) {
				postConfig.headers = {
					...postConfig.headers,
					...(customConfig?.headers ?? {}),
				};
			}
			try {
				const response = await fetch(
					(customHost ? customHost : API_HOST) + url,
					{
						method: 'POST',
						...postConfig,
						body: JSON.stringify(requestJSON),
					}
				);
				if (response.status === 500) {
					setError(response.type);
					return response.type;
				}
				setStatus(response?.ok);
				const apiData = await response.json();
				if (url.includes('charts?businessId')) {
					setIsLoaded(true);
					setData(apiData);
					return apiData;
				}

				const apiResponse = apiData.data ?? apiData;
				setIsLoaded(true);
				const payload = {
					statusCode: response.status,
					...apiResponse,
				};
				setData(payload);
				return payload;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[postConfig]
	);

	const formData = useCallback(async (url: string, requestJSON: any) => {
		setLoading(true);
		try {
			const response = await fetch(API_HOST + url, {
				method: 'POST',
				// headers: {
				//   Authorization: `Bearer ${accessToken}`,
				// },
				body: requestJSON,
			});
			const apiData = await response.json();
			setStatus(response?.ok);
			setIsLoaded(true);
			setData(apiData);
			setLoading(false);
			return apiData;
		} catch (err) {
			setError(err);
			return null;
		} finally {
			setLoading(false);
		}
	}, []);

	const put = useCallback(
		async (url: string, requestJSON?: any) => {
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PUT',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setStatus(response.status);
				setIsLoaded(true);
				setData(apiData.data);
				return apiData.data;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[postConfig]
	);

	const remove = useCallback(
		async (url: string, requestJSON?: any) => {
			try {
				const response = await fetch(API_HOST + url, {
					method: 'DELETE',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setStatus(response.status);
				setIsLoaded(true);
				setData(apiData.data);
				return apiData.data;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[postConfig]
	);

	const patch = useCallback(
		async (url: string, requestJSON?: any) => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PATCH',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setIsLoaded(true);
				const apiResponse = apiData.data ?? apiData;
				const payload = {
					statusCode: response.status,
					...apiResponse,
				};
				setData(payload);
				const retrunPayload = {
					statusCode: response.status,
					...apiData.data,
				};
				return retrunPayload;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[postConfig]
	);

	return {
		get,
		post,
		formData,
		put,
		data,
		status,
		error,
		loading,
		setLoading,
		remove,
		patch,
		isLoaded,
		setIsLoaded,
	};
};
