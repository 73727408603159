import { Loader } from '@storybook';
import { ASSETS } from 'constant';
import './face-setup.scss';

export const FaceSetupModal = () => {
	const { BIOMETRIC_SVG } = ASSETS;

	return (
		<div className="FaceSetup__wrapper">
			<div className="FaceSetup__loading-wrapper">
				<img src={BIOMETRIC_SVG} alt="" className="FaceSetup__loading-svg" />
				<p className="FaceSetup__loading-heading">
					We are preparing to initiate facial authentication.
				</p>

				<div className="FaceSetup__loading-instructions">
					Ensure you are in a well-lit environment. Stay close to the camera,
					but not too close.
				</div>
				<Loader type="loader" className="loader-blue" dimension={30} />
			</div>
		</div>
	);
};
