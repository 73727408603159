const IMAGE_HOST =
	'https://storage.googleapis.com/satschel-assets-public/assets/illustrations';

export const ASSETS: { [key: Uppercase<string>]: string } = {
	SIMPICI_SIGN_SVG: `${IMAGE_HOST}/SimpliciSign.svg`,
	DRAG_IMG: `${IMAGE_HOST}/darg-handle.png`,
	DOC_MISSING_SVG: `${IMAGE_HOST}/doc-missing.svg`,
	EMPTY_DATA_SVG: `${IMAGE_HOST}/empty-data.svg`,
	NO_RECIPIENT: `${IMAGE_HOST}/no-recipient.png`,
	SETTINGS_SVG: `${IMAGE_HOST}/settings.svg`,
	SUCESS_IMG: `${IMAGE_HOST}/success.gif`,
	BANNER_SVG: `${IMAGE_HOST}/banner.svg`,
	VOIDED_ERROR_SVG: `${IMAGE_HOST}/voided-error.svg`,
	BRAND_LOGO: `https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-wordmark.png`,
	BIOMETRIC_SVG: `${IMAGE_HOST}/Biometric.svg`,
	SINGLE_FILE_SVG: `${IMAGE_HOST}/Upload_Single_File.svg`,
};
