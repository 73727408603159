import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, Loader } from '@storybook';
import { Image } from '@storybook/image';
import {
	ISelectTermCondtionState,
	PolicyType,
	activeScreenState,
} from 'states';
import { formatDateWithTime } from 'utils';

import bgImage from '../../assests/images/pattern_01.png';
import {
	PaymentAcceptanceDetailsLabel,
	TransactionDecline,
	renderRecipientDetails,
} from './constant';

import './payment-acceptance.scss';
import { useGestures } from 'views/liveness/hooks';
import { livenessStatus } from 'constant';
import { userInformationState } from 'states/gesture-state';
import { useLocation } from 'hooks/location';

interface ISender {
	name: string;
	email: string;
}
interface ITransaction {
	amount: string;
	sender: ISender;
	Date?: string;
}
interface PaymentAcceptanceProps {
	isLoader: boolean;
	userInfo: ITransaction;
	userDetails?: any;
}

type IRecipientDetailData = 'name' | 'email_id' | 'phone_no' | 'created_at';

export const PaymentAcceptance: React.FC<PaymentAcceptanceProps> = ({
	isLoader,
	userInfo,
	userDetails,
}) => {
	//local state
	const [isPaymentDecline, setIsPaymentDecline] = useState(false);
	const { locationInfo } = useLocation();
	const setSelectTermCondtion = useSetRecoilState<PolicyType>(
		ISelectTermCondtionState
	);

	const userInformation = useRecoilValue(userInformationState);
	const { user, customer, sender } = userDetails ?? {};
	const { DECLINED } = livenessStatus;
	// Initialize state for checkboxes
	const [checkboxState, setCheckboxState] = useState({
		'privacy-policy': false,
		'term-and-condition': false,
		'biometric-policy': false,
	});

	// Function to handle checkbox state changes
	const handleCheckboxChange = (event: {
		target: { name: any; checked: any };
	}) => {
		const { name, checked } = event.target;
		setCheckboxState((prevState) => ({
			...prevState,
			[name]: checked,
		}));
	};

	//global
	const setActiveScreen = useSetRecoilState(activeScreenState);

	// props data
	const { amount } = userInfo ?? {};

	// set to liveness information
	const handleClickAccept = () => {
		setActiveScreen('liveness-information');
	};

	//hooks
	const { customerPatchStatus } = useGestures();

	// set decline payment
	const handleClickDecline = useCallback(() => {
		setIsPaymentDecline(true);
		customerPatchStatus(
			DECLINED,
			userInformation.customer?.id.toString() ?? '',
			'',
			locationInfo
		);
	}, [locationInfo, userInformation]);

	// Render Header
	const renderHeader = useMemo(
		() => (
			<div className="PaymentAcceptance__header">
				<div className="PaymentAcceptance__header__title">
					{PaymentAcceptanceDetailsLabel.Heading}
				</div>
				<div className="PaymentAcceptance__header__sub-title">
					{PaymentAcceptanceDetailsLabel.SubHeading}
				</div>
			</div>
		),
		[]
	);

	// render card details with amount details sending
	const renderCard = useMemo(
		() => (
			<div className="PaymentAcceptance__card">
				<img
					src={bgImage}
					alt="pattern"
					className="PaymentAcceptance__card__image"
				/>
				<div className="PaymentAcceptance__card__doller_icon_wrapper">
					<i className="ri-money-dollar-circle-line"></i>
				</div>
				<div className="PaymentAcceptance__card__investment_wrapper">
					<div className="PaymentAcceptance__card__amount_paid_text">
						{PaymentAcceptanceDetailsLabel.Amount}
					</div>
					<div className="PaymentAcceptance__card__amount_paid">
						{`$${parseFloat(amount).toFixed(2) ?? 0}`}
						<span className="PaymentAcceptance__card__amount_paid--dollorUsd">
							{PaymentAcceptanceDetailsLabel.DollorUSD}
						</span>
					</div>
				</div>
			</div>
		),
		[]
	);

	// render recipient details
	const renderDetails = useMemo(
		() =>
			Object.keys(renderRecipientDetails).map((data: string) => {
				return (
					<div className="PaymentAcceptance--Card-Details" key={data}>
						<div className="PaymentAcceptance--Card-Details__Details">
							<div className="PaymentAcceptance--Card-Details__Details--keys">
								{renderRecipientDetails?.[data as IRecipientDetailData] ?? '--'}
							</div>

							<div className="PaymentAcceptance--Card-Details__Details--values">
								{sender?.[data] ?? formatDateWithTime(customer?.[data]) ?? '--'}
							</div>
						</div>
					</div>
				);
			}),
		[user, customer, sender]
	);

	const renderDeclinePayment = useMemo(
		() => (
			<div className="PaymentAcceptance--container__Decline">
				<Image fileName="paymentDecline.svg" />
				<div className="PaymentAcceptance--container__Decline-header">
					{TransactionDecline.Header}
				</div>
				<div className="PaymentAcceptance--container__Decline-subHeader">
					{TransactionDecline.SubHeader}
				</div>
			</div>
		),
		[]
	);

	const handleBtnState = useMemo(() => {
		return !Object.values(checkboxState).every((value) => value);
	}, [checkboxState]);

	const handlePage = useCallback(
		(selectedTerm: PolicyType) => {
			setSelectTermCondtion(selectedTerm);
			setActiveScreen('term-and-condition');
		},
		[setActiveScreen]
	);
	return (
		<div className="PaymentAcceptance--container">
			{isLoader ? (
				<Loader />
			) : isPaymentDecline ? (
				<>{renderDeclinePayment}</>
			) : (
				<div className="PaymentAcceptance">
					{renderHeader}
					<div className="Card-Details">
						{renderCard}
						{renderDetails}
						<div className='PaymentAcceptance__footer__actions-wrapper'>
						<div className="PaymentAcceptance__footer__actions">
							<input
								className="PaymentAcceptance__checkbox"
								type="checkbox"
								name="term-and-condition"
								checked={checkboxState['term-and-condition']}
								onChange={handleCheckboxChange}
							/>
							<label htmlFor="tc-checkbox" />
							<div className="PaymentAcceptance__action__content">
								I agree to 
								<span
									onClick={() => handlePage('term-and-condition')}
									className="PaymentAcceptance__terms"
								>
									Terms and Conditions
								</span>
							</div>
						</div>
						<div className="PaymentAcceptance__footer__actions">
							<input
								className="PaymentAcceptance__checkbox"
								type="checkbox"
								name="privacy-policy"
								checked={checkboxState['privacy-policy']}
								onChange={handleCheckboxChange}
							/>
							<label htmlFor="tc-checkbox" />
							<div className="PaymentAcceptance__action__content">
								I agree to
								<span
									onClick={() => handlePage('privacy-policy')}
									className="PaymentAcceptance__terms"
								>
									Privacy policy
								</span>
							</div>
						</div>

						<div className="PaymentAcceptance__footer__actions">
							<input
								className="PaymentAcceptance__checkbox"
								type="checkbox"
								name="biometric-policy"
								checked={checkboxState['biometric-policy']}
								onChange={handleCheckboxChange}
							/>
							<label htmlFor="tc-checkbox" />
							<div className="PaymentAcceptance__action__content">
								I consent to the collection, use, sharing and retention of my
								biometric data as described in
								<span
									onClick={() => handlePage('biometric-policy')}
									className="PaymentAcceptance__terms"
								>
									Biometric policy
								</span>
							</div>
						</div>
						</div>

						<div className="PaymentAcceptance--btn">
							<div className="PaymentAcceptance--btn--decline">
								<Button
									label="Decline"
									type="button__filled button__filled--primary button__large button__block btn-width"
									handleClick={handleClickDecline}
								/>
							</div>
							<div className="PaymentAcceptance--btn--accept">
								<Button
									label="Accept"
									type="button__filled button__filled--primary button__large btn-width"
									handleClick={handleClickAccept}
									disabled={handleBtnState}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
