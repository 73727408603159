import { atom } from 'recoil';

export type IScreenRoutes =
	| 'liveness-information'
	| 'liveness-verification'
	| 'payment-approval'
	| 'success-screen'
	| 'camera-permission-required'
	| 'face-not-match-screen'
	| 'recipient-rejected-screen'
	| 'loading-results'
	| 'connection-not-created'
	| 'resctriction-screen'
	| 'term-and-condition';

export type PolicyType = 'term-and-condition' | 'privacy-policy' | 'biometric-policy';

export const activeScreenState = atom<IScreenRoutes>({
	key: 'active-screen-state-key',
	default: 'liveness-information',
});

export const IsLiveNessDone = atom<boolean>({
	key: 'is-liveness-done-by-user',
	default: false,
});

export const IsFaceMeshLoadedState = atom<boolean>({
	key: 'is-face-modal-setup-state-key',
	default: false,
});

export const IsWebRtcConnectedState = atom<boolean>({
	key: 'is-web-rtc-setup-key',
	default: false,
});

export const IsSentSignalEndState = atom<boolean>({
	key: 'is-web-rtc-end-signal-key',
	default: false,
});

export const ISelectTermCondtionState = atom<PolicyType>({
	key: 'selected-policy-key',
	default: 'term-and-condition',
});
