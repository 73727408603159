import { atom } from 'recoil';

import { IWebAuthDetails } from './types';

export const WebAuthUserDetails = atom<IWebAuthDetails>({
	key: 'webAuthUserDetails',
	default: {
		name: '',
		email: '',
		id: '',
	},
});

export const IsWebAuthSuccessfullState = atom<boolean>({
	key: 'Is-web-auth-done',
	default: false,
});
