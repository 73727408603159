/* eslint-disable no-alert */
/* eslint-disable prefer-const */
import * as vision from '@mediapipe/tasks-vision';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IsFaceMeshLoadedState, activeScreenState } from 'states';
import { IsStartFunctionCalledState } from 'states/gesture-state';

const { FaceLandmarker, FilesetResolver, DrawingUtils } = vision;
interface IScreenResolution {
	width: { ideal: number };
	height: { ideal: number };
}
export const useFace = () => {
	const faceLandmarker = useRef<vision.FaceLandmarker>();
	const setLoaded = useSetRecoilState(IsFaceMeshLoadedState);
	const webcamRunning = useRef(false);
	let initialSize: IScreenResolution = {
		width: { ideal: 600 },
		height: { ideal: 400 },
	};
	let videoWidth = 450;
	const lastVideoTime = useRef(-1);
	const results = useRef<vision.FaceLandmarkerResult>();
	const canvasElement = useRef<HTMLCanvasElement>();
	const videoElement = useRef<HTMLVideoElement>();
	const videoBlendShapes = useRef<HTMLElement>();
	const setActiveScreen = useSetRecoilState(activeScreenState);
	const [isCameraStart, setCameraStart] = useState(false);
	const IsStartFunctionCalled = useRecoilValue(IsStartFunctionCalledState)
	//Arun Kumar : Update screen width
	const updateScreenWidth = () => {
		if (window.innerWidth > videoWidth) {
			initialSize = {
				width: { ideal: 600 },
				height: { ideal: 400 },
			};
		} else {
			initialSize = {
				width: { ideal: window.innerWidth },
				height: { ideal: window.innerHeight / 2 + 50 },
			};
		}
	};

	useEffect(() => {
		//Arun Kumar : Add an event listener to update the screenWidth whenever the window is resized.
		updateScreenWidth();
		window.addEventListener('resize', updateScreenWidth);

		//Arun Kumar : Clean up the event listener when the component unmounts.
		return () => {
			window.removeEventListener('resize', updateScreenWidth);
		};
	}, []);

	useEffect(() => {
		// Feature detection
		if (navigator?.permissions && navigator?.permissions.query) {
			// Cast "camera" to PermissionName
			const permissionName = 'camera';

			const handlePermissionChange = (permissionStatus: PermissionStatus) => {
				if (permissionStatus.state === 'granted') {
					setActiveScreen('liveness-verification');
				}
			};

			// Use the casted permissionName to query for camera permission
			navigator.permissions.query({ name: permissionName as any }).then((permissionStatus) => {
					// Event handling using addEventListener
					permissionStatus.addEventListener('change', () => {
						handlePermissionChange(permissionStatus);

						// eslint-disable-next-line no-console
						console.log(
							`Camera permission changed to ${permissionStatus.state}`
						);
					});
				}).catch((error) => {
					// eslint-disable-next-line no-console
					console.error('Error querying camera permission:', error);
				});
		} else {
			// eslint-disable-next-line no-console
			console.error('navigator.permissions.query is not supported');
		}
	}, []); // Empty dependency array means this useEffect will run once on mount

	// Face mask color for connectors
	const faceMaskConnectorColor = '#C0C0C070';

	// eslint-disable-next-line @typescript-eslint/ban-types
	const currentStreamRef = useRef<Function>();

	// Check if webcam access is supported.
	const isGetingUserMedia = () => {
		return !!(navigator?.mediaDevices && navigator?.mediaDevices.getUserMedia);
	};

	// Showing value of gesture
	const drawBlendShapes = useCallback(
		(el: HTMLElement, blendShapes: vision.Classifications[]) => {
			if (!blendShapes.length) {
				return;
			}

			let htmlMaker = '';
			if (blendShapes[0]) {
				blendShapes[0].categories.forEach((shape) => {
					htmlMaker += `
            <li class="blend-shapes-item">
              <span class="blend-shapes-label">${
								shape.displayName || shape.categoryName
							}</span>
              <span class="blend-shapes-value" style="width: calc(${
								+shape.score * 100
							}% - 120px)">${(+shape.score).toFixed(4)}</span>
            </li>
          `;
				});
			}
			el.innerHTML = htmlMaker;
		},
		[]
	);

	// Drawing video streaming on canvas with mask
	const drawCanvas = useCallback(async () => {
		const canvasCtx = canvasElement?.current?.getContext('2d');
		const canvas = canvasElement?.current;
		const video = videoElement?.current;

		try {
			if (!canvasCtx || !video || !canvas) {
				return;
			}
			// Check if video dimensions are valid
			if (video.videoWidth > 0 && video.videoHeight > 0) {
				const drawingUtils = new DrawingUtils(canvasCtx);
				const getVideoWidth = initialSize?.width.ideal;
				const getVideoHeight = initialSize?.height.ideal;
				video.style.width = getVideoWidth + 'px';
				video.style.height = getVideoHeight + 'px';
				video.style.marginLeft = window.innerWidth > 450 ? '0px' : '-10px';
				canvas.style.width = getVideoWidth + 'px';
				canvas.style.height = getVideoHeight + 'px';
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				canvas.style.marginLeft = window.innerWidth > 450 ? '0px' : '-10px';

				let startTimeMs = performance.now();
				if (lastVideoTime.current !== video.currentTime) {
					lastVideoTime.current = video.currentTime;
					results.current = faceLandmarker.current?.detectForVideo(
						video,
						startTimeMs
					);
				}
				if (results.current?.faceLandmarks) {
					for (const landmarks of results.current.faceLandmarks) {
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_TESSELATION,
							{ color: faceMaskConnectorColor, lineWidth: 1 }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
							// { color: "#FF3030" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
							// { color: "#FF3030" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
							// { color: "#30FF30" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
							// { color: "#30FF30" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
							// { color: "#E0E0E0" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_LIPS,
							// { color: "#E0E0E0" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
							// { color: "#FF3030" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
							// { color: "#30FF30" }
							{ color: faceMaskConnectorColor }
						);
					}
				}

				currentStreamRef.current?.(results.current);

				if (videoBlendShapes.current) {
					drawBlendShapes(
						videoBlendShapes.current,
						results.current?.faceBlendshapes ?? []
					);
				}

				// Call this function again to keep predicting when the browser is ready.
				if (webcamRunning.current === true) {
					window.requestAnimationFrame(drawCanvas);
				}
			}
		} catch (error) {
			// it helpful to get error in canvas draw
			// eslint-disable-next-line no-console
			console.warn('error', error);
		}
	}, [drawBlendShapes, initialSize]);

	const updatefunction = useCallback(() => {
		setLoaded(true);
	}, []);

	const enableCam = useCallback(
		async (draw?: boolean) => {
			if (!faceLandmarker.current) {
				// eslint-disable-next-line no-console
				console.log('Wait! faceLandmarker not loaded yet.');
				// return;
			}

			if (!isGetingUserMedia) {
				// eslint-disable-next-line no-console
				console.error('No camera access');
				// return;
			}

			const devices = await navigator?.mediaDevices?.enumerateDevices();
			let frontCamera = devices?.find(
				(device) =>
					device?.kind === 'videoinput' &&
					device?.label.toLowerCase().includes('front')
			);

			if (!frontCamera) {
				// If front camera is not found, use any available video input device
				frontCamera = devices?.find((device) => device?.kind === 'videoinput');
			}

			const idealSize = {
				width: {
					ideal:
						window.innerWidth > videoWidth
							? initialSize?.width.ideal
							: initialSize?.height.ideal,
				},
				height: {
					ideal:
						window.innerWidth > videoWidth
							? initialSize.height.ideal
							: initialSize.width.ideal,
				},
			};

			const videoStream = {
				video: {
					deviceId: frontCamera?.deviceId,
					facingMode: 'user', // Use "user" for front camera
					...idealSize,
				},
			};

			// Activate the webcam stream.
			navigator?.mediaDevices
				?.getUserMedia(videoStream)
				.then((stream) => {
					if (videoElement?.current) {
						videoElement.current.srcObject = stream;
						webcamRunning.current = true;
						if (draw) {
							videoElement.current.addEventListener('loadeddata', drawCanvas);
							videoElement.current.addEventListener(
								'loadeddata',
								updatefunction
							);
						}
					}
				})
				.catch(() => {
					setActiveScreen('camera-permission-required');
				});
		},
		[drawCanvas]
	);

	// Before we can use HandLandmarker class we must wait for it to finish
	// loading. Machine Learning models can be large and take a moment to
	// get everything needed to run.
	const start = useCallback(
		async ({
			video,
			canvas,
			blendShapes,
			draw = true,
			onResults,
		}: {
			video: HTMLVideoElement;
			canvas: HTMLCanvasElement;
			blendShapes?: HTMLElement | null;
			draw?: boolean;
			onResults?: (results?: vision.FaceLandmarkerResult) => void;
		}) => {
			videoElement.current = video;
			canvasElement.current = canvas;
			if (blendShapes) {
				videoBlendShapes.current = blendShapes;
			}


			currentStreamRef.current = onResults;
			const filesetResolver = await FilesetResolver.forVisionTasks(
				'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm'
			);

			if(!IsStartFunctionCalled){
			faceLandmarker.current = await FaceLandmarker.createFromOptions(
				filesetResolver,
				{
					baseOptions: {
						modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
						delegate: 'GPU',
					},
					outputFaceBlendshapes: true,
					runningMode: 'VIDEO',
					numFaces: 1,
				}
			);
			
			if (!isCameraStart) {
				setCameraStart(true);
				enableCam(draw);
			}
		}
		},
		[enableCam, isCameraStart]
	);

	// function for stop the camera
	const stopCamera = useCallback((video: HTMLVideoElement) => {
		videoElement.current = video;
		if (video) {
			const stream = video.srcObject;
			if (stream instanceof MediaStream) {
				const tracks = stream.getTracks();
				tracks.forEach((track) => {
					track.stop();
				});
				video.srcObject = null;
			}
		}
	}, []);

	const draw = useCallback(async () => {
		await drawCanvas();
	}, [drawCanvas]);

	return { start, draw, stopCamera };
};
