export const formatDateWithTime = (inputDateString: string): string => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const date = new Date(inputDateString);
	const month = months[date.getMonth()];
	const day = date.getDate();
	const year = date.getFullYear();
	const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
	const minutes = ('0' + date.getMinutes()).slice(-2); // Add leading zero if needed
	const period = date.getHours() < 12 ? 'AM' : 'PM';

	// Returning, if there is not data and time avaible 
	if (!month || !date || !year) {
		return '--';
	}

	return `${month} ${day}, ${year} ${hours}:${minutes} ${period}`; //if input (2024-01-07T12:45:00.000Z) then return Jan 7, 2024 12:45 PM
};
