import { atom } from 'recoil';
type User = {
	name: string;
	email_id: string;
	phone_no: string;
};

type Notification = {
	email: boolean;
	phone: boolean;
};

type Customer = {
	id: number;
	notification: Notification;
	updated_at: string | null;
	created_at: string;
	status: string;
	type: string;
	end_time: string | null;
	face_match: boolean;
};

type Gesture = {
	type: string;
	threshold: number;
	frequency: number;
	name: string;
	video: string;
	image: string;
	description: string;
	id: number;
};

type Liveness = {
	id: number;
	gesture: Gesture;
	status: string;
};

type Transaction = {
	amount: number;
	sender: {
		name: string;
		email: string;
	};
	start_time: Date;
};

type Data = {
	user?: User;
	customer?: Customer;
	liveness?: Liveness[];
	transaction?: Transaction;
};

interface IGesture {
	type: string;
	threshold: number;
	frequency: number;
	name: string;
	video: string;
	image: string;
	description: string;
	id: number | string;
	stepId: number | string;
	status: string;
	isSuccess?: boolean;
}

interface IGestureInfo {
	gesture: IGesture[];
	customerId: string;
	customerType?: string;
	face_match?: boolean;
	customerStatus?: string;
}

export const livenessGestureInfoState = atom<IGestureInfo>({
	key: 'liveness-gesture-type',
	default: { gesture: [], customerId: '' },
});

export const livenessGestureInfoLocalState = atom<IGestureInfo>({
	key: 'liveness-gesture-info-local-state',
	default: { gesture: [], customerId: '' },
});

export const userInformationState = atom<Data>({
	key: 'user-information--state',
	default: {},
});

export const GetIpAddress = atom<string>({
	key: 'get-ip-address',
	default: '',
});

export const CaptureGestureImage = atom<string>({
	key: 'capture-user-image',
	default: '',
});

// this state store user image only for customer status patch api
export const CustomerImage = atom<string>({
	key: 'customer-user-image',
	default: '',
});

export const UserVerficationGestureState = atom<any>({
	key: 'user-verification-gesture-face-match-state',
	default: { gesture: [] },
});
export const locationInfoState = atom<any>({
	key: 'loaction-info-state',
	default: {},
});

export const IsStartFunctionCalledState = atom<boolean>({
	key: 'start-function-called-state',
	default: false,
});
