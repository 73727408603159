export const STEP_VERIFICATION_MESSAGE = {
	TITLE: 'Liveness Verification',
	HEADING: 'Follow the steps carefully for successful verification.',
	STEPONE: 'Find a well-lit area for accurate detection.',
	STEPTWO:
		'Follow the on-screen prompts for specific actions and complete each action.',
	STEPTHREE:
		'On-screen visual indicator bar turns green when the specified action crosses the required threshold.',
};

export const REFERENCE_VIDEO_TEXT = 'Reference video';
export const LIVENESS_VERIFIED_SUCCESS_TEXT = 'Liveness Verified';
export const LIVENESS_VERIFIED_SUCCESS_SUB_TEXT = '';

export const GESTURE_LEVEL = {
	MIN: 'min',
	MID: 'mid',
	MAX: 'max',
};

export const livenessStatus = {
	STARTED: 'started',
	COMPLETED: 'completed',
	REJECTED: 'rejected',
	PENDING: 'pending',
	DECLINED: 'declined',
};

export const StepVerifiactionMessage = {
	HEADING: 'Follow the steps carefully for successful verification.',
	STEPONE: 'Find a well-lit area for accurate detection.',
	STEPTWO:
		'Follow the on-screen prompts for specific actions and complete each action.',
	STEPTHREE:
		'On-screen visual indicator bar turns green when the specified action crosses the required threshold.',
};

export const CameraAcessRequiredMessage = {
	SUBHEADING:
		"It looks like your camera is not enabled, or we don't have permission to access it.",
	STEPONE:
		'Ensure your camera is physically connected(in case of external webcam) and turned on.',
	STEPTWO: 'From your browser settings and allow camera permissions.',
};

export const LoadingScreenMessage = {
	Heading: 'Verifying identity, please wait',
	SUBHEADING:
		'Your facial data is being processed and matched with our facial biometric data. This may take a few moments.',
};

export const FaceMatchScreenMessage = {
	Heading: 'Identity verification failed',
	SubHeading:
		'We are sorry, but your facial data did not match with our the facial biometric data.',
};
