import { useCallback } from 'react';

import { Button } from '@storybook';

import { offlinePage } from './constant';
import { OfflineSvg } from './image';

import './offline.scss';

export const Offline = () => {
	const onClickHandler = useCallback(() => {
		// Reload the current page in the browser
		window.location.reload();
	}, []);
	return (
		<div className="Offline--container">
			<div
				className="Offline--container__image"
				dangerouslySetInnerHTML={{
					__html: OfflineSvg,
				}}
			/>
			<p className="Offline--container__text">{offlinePage.heading}</p>
			<p className="Offline--container__desc">{offlinePage.subHeading}</p>
			<Button
				label={offlinePage.buttonLabel}
				handleClick={onClickHandler}
				type=" button__filled--primary button__large button__block Offline--container__retrybtn "
			/>
		</div>
	);
};
