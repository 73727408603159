import { Image } from '@storybook';

export const ConnectionBreaks = () => {
	return (
		<div className="Error--boundary">
			<Image fileName="500.svg" />
			<div className="Error--boundary__text-container">
				<p className="Error--boundary__text-heading">
					We are sorry, but it seems like something went wrong
				</p>
				<div className="Error--boundary__text-subheading">
					We apologize for any inconvenience this may cause and appreciate your
					patience. Our team is already on it and working to resolve the
					problem.
				</div>
			</div>
		</div>
	);
};
