import { Image } from '@storybook';
import { FaceMatchScreenMessage } from 'constant';
import './face-not-matched.scss';

export const FaceNotMatchedScreen = () => {
	return (
		<div className="Face-Result-wrapper">
			<div className="Face-Result-wrapper__box">
				<Image fileName="verification-failed.svg" />
				<p className="Face-Result-wrapper__heading">
					<b>{FaceMatchScreenMessage.Heading}</b>
				</p>
				<div className="Face-Result-wrapper__message">
					{FaceMatchScreenMessage.SubHeading}
				</div>
			</div>
		</div>
	);
};
