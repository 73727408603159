import { IButton } from '.';

import { FC, useMemo } from 'react';

import './button.scss';
import { useRecoilValue } from 'recoil';
import { IframePropsState } from '@storybook/environment';


export const Button: FC<IButton> = ({
	label,
	handleClick,
	loader,
	type,
	icon,
	height,
	disabled,
	buttonType,
	prefixIcon,
	minHeight,
	width,
}) => {
	const { btnPrimary, btnSecondary } = useRecoilValue(IframePropsState);

	const color = useMemo(() => {
		if (/button__filled--primary/gi.test(type)) {
			return btnPrimary;
		} else if (/button__filled--secondary/gi.test(type)) {
			return btnSecondary;
		}
	}, [btnPrimary, btnSecondary, type]);

	return (
		<button
			className={`button ${type} ${disabled ? 'button--disabled' : ''}`}
			onClick={handleClick}
			style={{ minHeight, height, width, backgroundColor: color }}
			disabled={disabled}
			type={buttonType}
		>
			{prefixIcon && <i className={prefixIcon} />}
			<div className="button-label-txt">{label}</div>
			{loader}
			{icon && <i className={icon} />}
		</button>
	);
};
 