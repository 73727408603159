interface IOneTrustToken {
	url: string;
	tokenId: string;
}

export const PRIVACY_POLICY_LINK = 'https://simplici.io/privacy-notice';

export const TERMS_CONDTION_ONETRUST_TOKEN: IOneTrustToken = {
	url: 'https://privacyportal-cdn.onetrust.com/f28abd65-17b0-4df7-b558-d20b86121b10/privacy-notices/9be91fa8-24e5-46a2-8770-9f90ee82e316.json',
	tokenId: 'otnotice-9be91fa8-24e5-46a2-8770-9f90ee82e316',
};

export const BIOMETRIC_POLICY_ONETRUST_TOKEN: IOneTrustToken = {
	url: 'https://privacyportal-cdn.onetrust.com/f28abd65-17b0-4df7-b558-d20b86121b10/privacy-notices/f75b688a-dfd8-40f2-b205-6a9d7a72fe7a.json',
	tokenId: 'otnotice-f75b688a-dfd8-40f2-b205-6a9d7a72fe7a',
};
