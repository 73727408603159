import { Image } from '@storybook/image';
import './camera-access-required.scss';
import { CameraAcessRequiredMessage } from 'constant';
export const CameraAccessRequired = () => {
	return (
		<>
			<div className="CameraAccessRequired--container">
				<div className="CameraAccessRequired--container__imageheading">
					<Image
						fileName="nocamera.svg"
						className="CameraAccessRequired--container__imageheading__image"
					/>
					<p className="CameraAccessRequired--container__imageheading__mainheading">
						Camera access required
					</p>
					<p className="CameraAccessRequired--container__imageheading__subheading">
						{CameraAcessRequiredMessage.SUBHEADING}
					</p>
				</div>
				<div className="CameraAccessRequired--container__stepdetails">
					<p className="CameraAccessRequired--container__stepdetails__follow">
						Follow these steps:
					</p>
					<ul className="CameraAccessRequired--container__stepdetails__list">
						<li className="CameraAccessRequired--container__stepdetails__list__steps">
							{CameraAcessRequiredMessage.STEPONE}
						</li>
						<li className="CameraAccessRequired--container__stepdetails__list__steps">
							{CameraAcessRequiredMessage.STEPTWO}
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};
