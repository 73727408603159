import { Image } from '@storybook';
import {
	LIVENESS_VERIFIED_SUCCESS_SUB_TEXT,
	LIVENESS_VERIFIED_SUCCESS_TEXT,
} from 'constant';

import './success-screen.scss';

export const SuccessScreen = () => {
	return (
		<div className="verification__success">
			<div className="verification__success_img-wrapper">
				<Image fileName="success.gif" className="verification__success-img" />
			</div>
			<div className="verification__success-message">
				{LIVENESS_VERIFIED_SUCCESS_TEXT}
			</div>
			<div className="verification__success-description">
				{LIVENESS_VERIFIED_SUCCESS_SUB_TEXT}
			</div>
		</div>
	);
};
