/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Image } from '@storybook';
import { Component, ErrorInfo, ReactNode } from 'react';
import './index.scss';

interface ErrorBoundaryProps {
	children: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

export class ErrorBoundary extends Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(_: Error): Partial<ErrorBoundaryState> {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		// You can log the error to a service like Sentry
		// eslint-disable-next-line no-console
		console.error('Error caught by error boundary:', error, errorInfo);
	}
    handleReload = () => {
        // Reload the entire page
        window.location.reload();
      };

	render(): ReactNode {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<>
					<div className="Error--boundary">
						<Image fileName="500.svg" />
						<div className="Error--boundary__text-container">
							<p className="Error--boundary__text-heading">
								We are sorry, but it seems like something went wrong
							</p>
							<div className="Error--boundary__text-subheading">
								We apologize for any inconvenience this may cause and appreciate
								your patience. Our team is already on it and working to resolve
								the problem.
							</div>
						</div>
                        <Button label={'Retry'} 	type="button__filled--primary" handleClick={this.handleReload} />
					</div>
				</>
			);
		}

		return this.props.children;
	}
}
