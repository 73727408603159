import { useCallback } from 'react';

import { CHALLENGE } from './constants';
// import { useSetRecoilState } from 'recoil';
import { IsWebAuthSuccessfullState } from './state';
import { useSetRecoilState } from 'recoil';

export const useWebAuthentication = () => {
	const setIsWebAuthSuccessfull = useSetRecoilState(IsWebAuthSuccessfullState);

	const registerNewCredentials = useCallback(
		async (callback: () => void, userInfo: any) => {
			const enc = new TextEncoder();
			const publicKey: PublicKeyCredentialCreationOptions = {
				rp: { name: window.location.host ?? 'verification.stage.satschel.com' },
				user: {
					name: userInfo.displayName ?? '',
					id: enc.encode(userInfo.id ?? ''),
					displayName: userInfo.displayName ?? '',
				},
				pubKeyCredParams: [
					{ type: 'public-key', alg: -7 },
					{
						type: 'public-key',
						alg: -257,
					},
				],
				challenge: enc.encode(CHALLENGE),
				authenticatorSelection: { userVerification: 'required' },
			};
			try {
				const res = (await navigator.credentials.create({
					publicKey: publicKey,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				})) as any;
				// eslint-disable-next-line no-console
				console.log(res);
				setIsWebAuthSuccessfull(true);
				callback();

				return Promise.resolve();
			} catch (err) {
				setIsWebAuthSuccessfull(true);
				// if (err instanceof DOMException) {
				// 	if (
				// 		err.name === 'NotAllowedError' ||
				// 		err.name === 'InvalidStateError'
				// 	) {
				// 		// Handle incomplete interaction with WebAuthn UI
				// 		setIsWebAuthSuccessfull(false);
				// 	}
				// } else {
				// 	// Handle other errors
				// }
			callback();
			}
		},
		[]
	);

	return {
		registerNewCredentials,
	};
};
