export interface IRecipientDetails {
	name: string;
	email_id: string;
	phone_no: string;
	created_at: string;
}

export const PaymentAcceptanceDetailsLabel = {
	Heading: 'Payment Acceptance',
	SubHeading:
		'You have received a payment. Please review the details below and choose your preferred action.',
	Amount: 'Amount',
	DollorUSD: 'USD',
	Sender: 'Name',
	Email: 'Email',
	Phone: 'Mobile No.',
	Date: 'Date & Time',
};

export const TransactionDecline = {
	Header: 'Transaction declined',
	SubHeader: 'You’ve declined this transaction.',
};

export const renderRecipientDetails: IRecipientDetails = {
	name: 'Sender’s name :',
	email_id: 'Sender’s email :',
	phone_no: 'Sender’s contact :',
	created_at: 'Date & Time :',
};
